.adm-toast-mask .adm-toast-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.adm-toast-mask .adm-toast-main {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 5.44rem;
  max-height: 70%;
  overflow: auto;
  color: white;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.213333rem;
  pointer-events: all;
  font-size: var(--adm-font-size-7);
  line-height: 1.5;
  box-sizing: border-box;
}

.adm-toast-mask .adm-toast-main-text {
  padding: 0.32rem;
  min-width: 0;
}

.adm-toast-mask .adm-toast-main-icon {
  padding: 0.933333rem 0.32rem;
  min-width: 4rem;
}

.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 0.213333rem;
  font-size: 0.96rem;
  line-height: 1;
}

.adm-toast-loading {
  --size: 1.28rem;
  margin: 0 auto 0.213333rem;
}